<template>
  <div>
    <v-toolbar :clipped-left="clipped" fixed app class="tool-bar" style="box-shadow: none; ">
      <!-- <AgrifarmLogo style="width: 150px;"/> -->
      <KlimaTeknikLogo style="width: 230px;"/>
      <v-toolbar-title class="ubuntu title"></v-toolbar-title>

      <v-spacer></v-spacer>
      <div class="user-avatar hidden-md-and-down mr-4">
        <p>{{user.name}}</p>
        <v-avatar color="grey lighten-4" style size="35px">
          <v-icon color="#1f242e">perm_identity</v-icon>
          
        </v-avatar>
      </div>

      <v-avatar color="grey lighten-4" size="30px" hidden-sm-and-down class="hidden-md-and-down">
        <v-menu bottom origin="center center" transition="slide-x-transition">
          <template v-slot:activator="{ on }">
            <img :src="require(`~/assets/flags/${$i18n.locale}.png`)" alt="Avatar" v-on="on">
          </template>

          <v-list>
            <v-list-tile v-for="(item, i) in languages" :key="i" @click="changeLanguage(item)">
              <v-list-tile-title>{{ item.title }}</v-list-tile-title>
            </v-list-tile>
          </v-list>
        </v-menu>
      </v-avatar>

      <v-toolbar-side-icon
        color="#7EBB47"
        class="hidden-lg-and-up"
        @click="mobileDrawer = !mobileDrawer"
      />
    </v-toolbar>
  </div>
</template>
<script>
import AgrifarmLogo from "~/components/misc/AgrifarmLogo.vue";
import KlimaTeknikLogo from "~/components/misc/KlimaTeknikLogo.vue";
import SearchBox from "~/components/layout/SearchBox.vue";
import LanguageSelector from "~/components/LanguageSelector.vue";

import { mapState, mapMutations } from "vuex";

export default {
  props: ["controllers"],
  data() {
    return {
      clipped: false,
      languages: [
        {
          title: "Dansk",
          value: "da"
        },
        {
          title: "English",
          value: "en"
        }
      ]
    };
  },
  computed: {
    mobileDrawer: {
      get() {
        return this.$store.state.layout.mobileDrawer;
      },
      set(val) {
        this.$store.commit("layout/setMobileDrawer", val);
      }
    },
    languageImg() {},
    user() {
      return this.$store.state.auth.user;
    }
  },
  methods: {
    toggleFilter(show) {},
    changeLanguage(lang) {
      this.$i18n.defaultLocale = lang.value;
      this.switchLocalePath(lang.value);
      this.$router.push(this.localePath({ name: "list" }, lang.value));
      
    }
  },
  components: {
    SearchBox,
    AgrifarmLogo,
    KlimaTeknikLogo,
    LanguageSelector
  }
};
</script>
<style lang="stylus" scoped>
.top-logo {
  max-width: 20px;
  margin-right: -10px;
}

.v-toolbar--fixed.theme--dark {
  padding-right: 0 !important;
}

.filter-icon {
  &:hover {
    path {
      fill: #fff;
    }
  }

  path {
    fill: #bcbcbc;
    transition: 0.6s all;
  }
}

.user-avatar {
  display: flex;
  align-items: center;

  p {
    color: #3f3f3f;
    margin-top: 15px;
    margin-right: 10px;
  }
}
</style>


